import { useState } from "react";
import PropTypes from "prop-types";
import { GridImage, LogoIcon } from "../assets";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { IoCheckmarkCircle } from "react-icons/io5";

// Step 1 Component
const Step1 = ({ formData, handleChange }) => (
  <div>
    <h2 className="text-xl lg:text-3xl mb-4 lg:mb-10">What is your name?</h2>
    <label>
      <input
        type="text"
        name="fullName"
        placeholder="Your full name"
        value={formData.fullName}
        onChange={handleChange}
        className="w-full border border-black rounded-lg p-4 lg:p-6 mb-10"
      />
    </label>
  </div>
);

Step1.propTypes = {
  formData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

// Step 2 Component
const Step2 = ({ formData, handleChange }) => (
  <div>
    <h2 className="text-xl lg:text-3xl mb-4 lg:mb-10">Email address?</h2>
    <label>
      <input
        type="email"
        name="email"
        placeholder="johnson@example.com"
        value={formData.email}
        onChange={handleChange}
        className="w-full border border-black rounded-lg p-4 lg:p-6 mb-10"
      />
    </label>
  </div>
);

Step2.propTypes = {
  formData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

// Step 3 Component with Dropdown
const Step3 = ({ formData, handleChange }) => (
  <div>
    <h2 className="text-xl lg:text-3xl mb-4 lg:mb-10">
      Which of our products are you most interested in investing in?
    </h2>
    <label>
      <select
        name="project"
        value={formData.project}
        onChange={handleChange}
        required
        className="w-full border border-black rounded-lg p-4 lg:p-6 mb-10"
      >
        <option disabled value="">
          Select Project
        </option>
        <option value="Fasfill">Fasfill</option>
        <option value="Agrivest247">Agrivest247</option>
      </select>
    </label>
  </div>
);

Step3.propTypes = {
  formData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

// Step 4 Component
const Step4 = ({ formData, handleChange }) => (
  <div>
    <h2 className="text-xl lg:text-3xl mb-4 lg:mb-10">
      By agreeing, our team will reach out to you via email with details on the
      investment process.
    </h2>
    <label className="mb-10">
      <input
        type="checkbox"
        name="agreement"
        checked={formData.agreement}
        onChange={handleChange}
        className="mb-10"
      />{" "}
      Yes, I agree
    </label>
  </div>
);

Step4.propTypes = {
  formData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

// Main GetStarted Component
const GetStarted = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    project: "",
    agreement: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Add this state

  // Handle input change
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Function to send email using Nodemailer on the server
  const sendEmail = async (data) => {
    try {
      const response = await fetch("https://gridhall-com.onrender.com/getStarted", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      // const response = await fetch("http://127.0.0.1:5001/getStarted", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(data),
      // });

      if (!response.ok) {
        throw new Error("Failed to send email");
      }

      console.log("Email successfully sent!");
    } catch (error) {
      console.error("Error sending email:", error);
      throw new Error("Failed to send email");
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (step === 4) {
      console.log("Submitting form...");
      setIsSubmitting(true); // Disable button during submission

      try {
        await sendEmail(formData);
        console.log("Email sent successfully!");
        setIsSubmitted(true);
        setSubmissionMessage(
          <div>
            <div className="flex justify-center">
              <IoCheckmarkCircle
                size={120}
                color="#008CFE"
                className="bg-blue-200 rounded-full p-3"
              />
            </div>
            <div className="flex flex-col gap-4 mt-10 text-center justify-center mb-4">
              <h1 className="text-lg">Successfully Submitted!</h1>
              <p className="text-sm">
                The team will reach out to you shortly with the investment
                details via email.
              </p>
              {/* <div className="relative text-center space-y-10">
                <a
                  href="/Products"
                  className="inline-flex gap-2 z-50 items-center bg-primary text-white px-10 py-4 rounded hover:bg-blue-700 transition relative"
                >
                  Back to home
                </a>
              </div> */}
            </div>
          </div>
        );

        // Redirect after 3 seconds
        setTimeout(() => {
          window.location.href = "/Products"; // Replace with the actual redirect URL
        }, 1000);
      } catch (error) {
        console.error("Error sending email:", error);
        setSubmissionMessage("There was an error submitting the form.");
        setIsSubmitting(false); // Re-enable button if submission fails
      }
    }
  };

  // Proceed to the next step or submit on last step
  const nextStep = async () => {
    if (step === 4) {
      await handleSubmit(); // Submit the form on the last step
    } else {
      setStep(step + 1);
    }
  };

  // Go back to the previous step
  const prevStep = () => {
    setStep(step - 1);
  };

  // Render the current step
  const renderStep = () => {
    if (isSubmitted) {
      return <h2>{submissionMessage}</h2>;
    }

    switch (step) {
      case 1:
        return <Step1 formData={formData} handleChange={handleChange} />;
      case 2:
        return <Step2 formData={formData} handleChange={handleChange} />;
      case 3:
        return <Step3 formData={formData} handleChange={handleChange} />;
      case 4:
        return <Step4 formData={formData} handleChange={handleChange} />;
      default:
        return null;
    }
  };

  return (
    <section className="w-[90%] lg:w-[65%] mx-auto relative flex flex-col items-center justify-center h-[calc(100vh)] translate-y-20">
      <div className="absolute inset-0 z-0">
        <img
          src={GridImage}
          alt="Grid Background"
          className="w-full h-full object-contain object-center"
        />
      </div>
      <div className="w-full lg:w-1/2 mx-auto absolute flex flex-col">
        <div className="flex justify-center items-center gap-2 mx-auto border border-black/10 bg-[#ECECEC] text-sm px-4 py-3 rounded-full mb-6">
          <img src={LogoIcon} alt="logo" className="w-8" />
          <p>Invest With Gridhall</p>
        </div>

        <div className="bg-white p-8 lg:p-16 rounded-3xl border drop-shadow-md">
          <div className="flex justify-between mb-4">
            <button
              onClick={
                isSubmitted
                  ? null
                  : step === 1
                  ? () => (window.location.href = "/Investment")
                  : prevStep
              }
              className={`text-gray-600 rounded flex items-center gap-2 ${
                isSubmitted ? "cursor-not-allowed hidden" : ""
              }`}
              disabled={isSubmitted}
            >
              <AiOutlineArrowLeft /> Back
            </button>
          </div>

          {renderStep()}

          <div className="flex justify-between mb-4">
            <button
              onClick={nextStep}
              disabled={isSubmitting}
              className={`bg-primary text-white px-10 py-4 rounded flex items-center gap-2 ${
                step === 4 ? "bg-submit" : ""
              } ${isSubmitting ? "cursor-not-allowed" : ""}`}
            >
              {step === 4 ? (
                "Submit"
              ) : (
                <>
                  Next <AiOutlineArrowRight />
                </>
              )}
            </button>
          </div>
        </div>
        {/* indicator  */}

        <div className="flex justify-center gap-3 my-20">
          {[1, 2, 3, 4].map((stepNumber) => (
            <div
              key={stepNumber}
              className={`w-4 h-4 flex items-center justify-center rounded-full ${
                step === stepNumber ? "text-black" : "text-gray-300"
              }`}
            >
              •
            </div>
          ))}
        </div>
      </div>

      
    </section>
  );
};

export default GetStarted;
