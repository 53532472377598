import { Footer, Header } from "../components";
import {
  AgrivestSection,
  ContactSection,
  FasfillSection,
  HeroSection,
  InvestmentSection,
} from "../ui";

const Products = () => {
  return (
    <>
      <Header />
      <HeroSection />
      <FasfillSection />
      <AgrivestSection />
      <InvestmentSection />
      <ContactSection />
      <Footer />
      
    </>
  );
};

export default Products;
