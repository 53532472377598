import { AboutImage, GridImage, LogoTransparent } from "../assets";
import { motion } from "framer-motion";
import {
  fadeIn,
  // slideIn,
  staggerContainer,
  textVariant,
} from "../utils/motion";
import { Header } from "../components";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <>
    <Header />

    <section className="w-[90%] lg:w-[40%] mx-auto relative flex flex-col items-center justify-center">
      {/* Add GridImage as background */}
      <div className="absolute inset-0 z-0">
        <img
          src={GridImage}
          alt="Grid Background"
          className="w-full h-full object-cover"
        />
      </div>
      <img
        src={LogoTransparent}
        alt=""
        className="mx-auto object-center absolute top-0"
      />{" "}
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className="relative z-10 text-center pb-40 space-y-4"
      >
        <div className="mt-40">
          <motion.h1 variants={textVariant(0.5)} className="h1 mb-5">
            About Us
          </motion.h1>
          <motion.p
            variants={fadeIn("up", "tween", 0.2, 1)}
            className="text-black text-lg mb-10 text-left"
          >
            GridHall Incorporated was started in 2021 as a software company by
            founding <br /> members Mukhtar Gazali and Donal O&apos;Grady.
          </motion.p>
        </div>
        <motion.div variants={fadeIn("up", "tween", 0.2, 1)} className="flex-1">
          <img
            src={AboutImage}
            alt="Mukhtar Gazali and Donal O'Grady"
            className="rounded-lg shadow-md w-full"
          />
        </motion.div>
        <p className="text-gray-600 text-left leading-relaxed">
          "Our collective background in engineering and design allowed us to
          creatively initiate the build-up of several platforms like Fasfill,
          AlleyPay, Trustbreeje, and Agrivest247. We had successes in launching
          Fasfill and AlleyPay to market, learning through customer feedback,
          iterating and relaunching to market. Currently, our latest product is
          Agrivest247 and you can read more on {" "}<Link to="https://agrivest247.com" target="_blank" className="text-primary font-bold">Agrivest 247</Link>"
        </p>

        <div className="text-left">
          <h1 className="text-lg">Mukhtar Gazali</h1>
          <p>Founder and Chief Executive</p>
        </div>
        
      </motion.div>
    </section>
    </>
  );
};

export default AboutUs;
