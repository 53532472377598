import { GridImage, InvestmentImage } from "../assets";
import { motion } from "framer-motion";
import {
  fadeIn,
  slideIn,
  // slideIn,
  staggerContainer,
} from "../utils/motion";
import { Link } from "react-router-dom";
import { Header } from "../components";

const Investment = () => {
  return (
    <>
      <Header />
      <section className="w-[90%] md:w-[80%] mx-auto relative flex flex-col items-center justify-center h-screen ">
        {/* Add GridImage as background */}
        <div className="absolute inset-0 z-0">
          <img
            src={GridImage}
            alt="Grid Background"
            className="w-full h-full object-cover"
          />
        </div>

        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.25 }}
          className="lg:grid grid-cols-2 gap-20 items-center relative z-10 text-left space-y-10 lg:space-y-0 py-20"
        >
          <div className="space-y-8 ">
            <motion.h1
              variants={fadeIn("up", "tween", 0.2, 1)}
              className="text-4xl lg:text-6xl"
            >
              Secure shares in our{" "}
              <span className="text-gray-300">Angel stage</span>
            </motion.h1>
            <motion.p variants={fadeIn("up", "tween", 0.2, 1)}>
              Join us at the forefront of innovation by investing in our
              early-stage products. Be part of our growth and secure your stake
              in the future of technology today!
            </motion.p>
            <Link
              to="/GetStarted"
              className="inline-flex gap-2 items-center bg-primary text-white text-lg px-10 py-4 rounded hover:bg-blue-700 transition"
            >
              Get Started <span className="text-2xl">→</span>
            </Link>
          </div>
          <motion.div variants={slideIn("right", "tween", 0.2, 1)}>
            <img src={InvestmentImage} alt="" />
          </motion.div>
        </motion.div>
      </section>
    </>
  );
};

export default Investment;
