import { GridImage, Icon1, Icon2 } from "../assets";
import { motion } from "framer-motion";
import { fadeIn, staggerContainer, textVariant } from "../utils/motion";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { ScrollParallax } from "react-just-parallax";

const HeroSection = () => {
  return (
    <section
      id="Hero"
      className="h-[calc(100vh-100px)] flex items-center relative"
    >
      <div className="absolute inset-0 z-0">
        <img
          src={GridImage}
          alt="Grid Background"
          className="w-full h-full object-cover"
        />
      </div>

      <div className="w-[90%] md:w-[80%] mx-auto relative z-10">
        {" "}
        {/* Set relative z-index here */}
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.25 }}
          className="relative text-center space-y-10"
        >
          <motion.div
            variants={fadeIn("up", "tween", 0.2, 1)}
            className="inline-flex border border-black/10 bg-[#ECECEC] text-sm px-4 py-3 rounded-full"
          >
            Built by a team of experts
          </motion.div>
          <motion.h1 variants={textVariant(0.5)} className="h1">
            Our Products
          </motion.h1>
          <motion.p
            variants={fadeIn("up", "tween", 0.2, 1)}
            className="text-black text-lg md:text-xl"
          >
            We build innovative platforms designed to <span className="font-bold"> create wealth</span> and drive solutions for individuals and<br /> businesses  
          </motion.p>
          <AnchorLink
            href="#Fasfill"
            className="inline-flex gap-2 z-50 items-center bg-primary text-white px-10 py-4 rounded hover:bg-blue-700 transition relative" // Set relative positioning to ensure it's on top
          >
            View <span>↓</span>
          </AnchorLink>

          {/* Floating Icons */}
          <ScrollParallax isAbsolutelyPositioned>
            <ul className="hidden left-[5rem] bottom-[7.5rem] bg-n-9/40 backdrop-blur rounded-2xl xl:flex z-10">
              {" "}
              {/* Set z-index here */}
              <img
                src={Icon2}
                alt="Floating Icon 2"
                className="top-[0px] absolute"
              />
            </ul>
          </ScrollParallax>

          <ScrollParallax isAbsolutelyPositioned>
            <ul className="hidden absolute top-2/5 right-[5rem] bottom-[2rem] bg-n-9/40 backdrop-blur rounded-2xl xl:flex z-10">
              {" "}
              {/* Set z-index here */}
              <img src={Icon1} alt="Floating Icon 1" />
            </ul>
          </ScrollParallax>
        </motion.div>
      </div>
    </section>
  );
};

export default HeroSection;
