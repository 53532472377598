import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  AboutUs,
  ContactUs,
  FormSuccess,
  GetStarted,
  Home,
  Investment,
  Products,
  ThankYou
} from "../pages";
import LoadingSpinner from "../components/LoadingSpinner"; // Assume we'll create this component
import { useEffect, useState } from "react";

const AppRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust time as needed

    return () => clearTimeout(timer);
  }, []);
  return (
    <BrowserRouter>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/Products" element={<Products />} />
            <Route path="/About" element={<AboutUs />} />
            <Route path="/Investment" element={<Investment />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/GetStarted" element={<GetStarted />} />
            <Route path="/ThankYou" element={<ThankYou />} />
            <Route path="/FormSuccess" element={<FormSuccess />} />
          </Routes>
          {/* <Footer /> */}
        </>
      )}
    </BrowserRouter>
  );
};

export default AppRoutes;
