import { useState, useRef } from "react";
import { /* BsInstagram, */ BsLinkedin, BsTwitterX } from "react-icons/bs";
import { GrAttachment } from "react-icons/gr";
import { IoCopyOutline } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai"; // Import the delete icon

const ContactSection = () => {
  const [copyFeedback, setCopyFeedback] = useState("");
  const [formStatus, setFormStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState(""); // State to hold the file name
  const fileInputRef = useRef(null); // Ref to access the file input

  const handleCopyEmail = () => {
    const email = "info@gridhall.com";
    navigator.clipboard
      .writeText(email)
      .then(() => {
        setCopyFeedback("Copied!");
        setTimeout(() => setCopyFeedback(""), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        setCopyFeedback("Copy failed");
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name); // Set the file name to the selected file's name
    }
  };

  const handleRemoveFile = () => {
    setFileName(""); // Clear file name
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear file input
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   const form = e.target;
  //   const data = new FormData(form);

  //   // Prepare the request payload
  //   const payload = {
  //     firstName: data.get("firstName"),
  //     lastName: data.get("lastName"),
  //     email: data.get("email"),
  //     message: data.get("message"),
  //   };

  //   // Get file if attached
  //   const fileInput = form.attachment.files[0];
  //   let attachmentBase64 = "";
  //   if (fileInput) {
  //     attachmentBase64 = await toBase64(fileInput);
  //     data.append("attachment", attachmentBase64);
  //   }
  //   try {
  //     const response = await fetch("http://localhost:5001/contactForm", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(payload),
  //     });

  //     if (!response.ok) {
  //       const errorData = await response.json();
  //       setFormStatus(
  //         `Oops! There was a problem: ${
  //           errorData.message || "Unknown error occurred."
  //         }`
  //       );
  //     } else {
  //       form.reset();
  //       setFileName(""); // Clear file name after form submission
  //       window.location.href = "/FormSuccess";
  //     }
  //   } catch (error) {
  //     console.error("Fetch error:", error);
  //     setFormStatus(`Oops! There was a problem: ${error.message}`);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const toBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = (error) => reject(error);
  //   });
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const form = e.target;
    const data = new FormData(form); // Attach all form data including files

    try {
      const response = await fetch("https://gridhall-com.onrender.com/contactForm", {
        method: "POST",
        body: data, // send FormData directly
      });
      // const response = await fetch("http://localhost:5001/contactForm", {
      //   method: "POST",
      //   body: data, // send FormData directly
      // });

      if (!response.ok) {
        const errorData = await response.json();
        setFormStatus(
          `An error occured! : ${
            errorData.message || "Unknown error occurred."
          }`
        );
      } else {
        form.reset();
        setFileName(""); // Clear file name after form submission
        window.location.href = "/FormSuccess";
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setFormStatus(`An error occured!: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id="Contact" className="flex lg:h-screen mx-auto py-24">
      <div className="w-[90%] lg:w-[70%] mx-auto flex flex-col lg:flex-row items-center lg:gap-20">
        <div className="lg:w-1/2 text-center lg:text-left">
          <h2 className="text-4xl lg:text-6xl font-bold">Let’s talk</h2>
          <p className="text-lg text-gray-700 mt-4">
            Have a question or need assistance? Don’t hesitate to reach out!
            We’re always ready to help and look forward to connecting with you.
          </p>
          <div className="flex items-center justify-center lg:justify-normal mt-4 gap-2">
            <p className="text-lg text-gray-700 bg-gray-200 rounded p-2">
              info@gridhall.com
            </p>
            <button
              onClick={handleCopyEmail}
              className="flex items-center gap-1 text-primary hover:text-blue-700"
              aria-label="Copy email"
            >
              <IoCopyOutline className="text-primary" fontWeight={10} />
              {copyFeedback && <span className="text-sm">{copyFeedback}</span>}
            </button>
          </div>
          <div className="pt-10 flex justify-center lg:justify-start space-x-4">
            <ul className="flex gap-6">
              <li className="bg-primary p-3 rounded-full">
                <button
                  aria-label="Twitter"
                  className="flex items-center"
                  onClick={() =>
                    window.open("https://x.com/GridHall", "_blank")
                  }
                >
                  <BsTwitterX size={20} color="white" />
                </button>
              </li>
              <li className="bg-primary p-3 rounded-full">
                <button
                  aria-label="LinkedIn"
                  className="flex items-center"
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/gridhall-limited/",
                      "_blank"
                    )
                  }
                >
                  <BsLinkedin size={20} color="white" />
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="lg:w-1/2 mt-10 lg:mt-0">
          <form
            onSubmit={handleSubmit}
            className="bg-white p-6 lg:p-14 shadow-md rounded-lg flex flex-col lg:gap-6"
          >
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                className="border border-gray-300 rounded-lg p-3"
                required
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                className="border border-gray-300 rounded-lg p-3"
                required
              />
            </div>
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              className="border border-gray-300 rounded-lg p-3 mt-4 w-full"
              required
            />
            <textarea
              name="message"
              placeholder="Message"
              className="border border-gray-300 rounded-lg p-3 mt-4 w-full"
              rows="5"
              required
            ></textarea>
            <div className="lg:flex items-center justify-between">
              <div className="flex flex-col mt-4">
                <label className="flex items-center">
                  <input
                    type="file"
                    name="attachment"
                    className="hidden"
                    ref={fileInputRef} // Attach ref to the file input
                    onChange={handleFileChange} // Attach onChange handler
                  />
                  <span className="cursor-pointer flex items-center gap-2">
                    <GrAttachment /> Attach document
                  </span>
                </label>
                {fileName && (
                  <div className="flex items-center mt-2 text-gray-600">
                    <p className="text-sm">Attached: {fileName}</p>
                    <button
                      type="button"
                      onClick={handleRemoveFile}
                      className="ml-2 text-red-500"
                      aria-label="Remove file"
                    >
                      <AiOutlineClose />
                    </button>
                  </div>
                )}
              </div>
              <button
                type="submit"
                className={`bg-primary text-white px-6 py-3 rounded-lg transition hover:bg-blue-700 mt-4 ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loading}
              >
                {loading ? "Sending..." : "Send Message"}
              </button>
            </div>
            {formStatus && <p className="mt-4 text-center">{formStatus}</p>}
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
