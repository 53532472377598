import { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { NavLinks } from "../constants";
import { Logo } from "../assets";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { /* BsInstagram, */ BsLinkedin, BsTwitterX } from "react-icons/bs";

import { motion } from "framer-motion";

const Header = () => {
  const [toggle, setToggle] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false); // Added state for scroll detection

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < lastScrollY) {
        setVisible(true);
      } else {
        setVisible(false);
      }
      setLastScrollY(window.scrollY);
      setIsScrolled(window.scrollY > 0); // Update isScrolled based on scroll position
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <motion.nav
      className={`w-full flex items-center transition-transform duration-300 ease-in-out ${
        isScrolled ? "bg-white/80 backdrop-blur-sm" : "bg-transparent"
      } sticky top-0 z-30 ${visible ? "translate-y-0" : "-translate-y-full"}`}
    >
      <div
        className={`w-[90%] md:w-[80%] mx-auto hidden md:flex justify-between items-center sticky z-10 py-5 top-0`}
      >
        <div className={`flex gap-4 items-center`}>
          <Link to="/Products" className="flex items-center">
            <img src={Logo} alt="logo" className={`w-full`} />
          </Link>
        </div>
        <div className="hidden md:flex gap-10">
          {NavLinks.map((nav, index) => (
            <li key={index} className="list-none text-md">
              <NavLink
                to={nav.url}
                className={({ isActive }) =>
                  isActive
                    ? "border-b-2 border-primary pb-2 font-semibold"
                    : "pb-2"
                }
              >
                {nav.title}
              </NavLink>
            </li>
          ))}
        </div>

        <div className="flex">
          <a
            href="/ContactUs"
            className="bg-primary text-white px-10 py-4 rounded hover:bg-blue-700"
          >
            Contact Us
          </a>
        </div>
      </div>

      {/* Mobile Nav */}
      <div
        className={`w-full flex md:hidden justify-between items-center py-4`}
      >
        <div className={`flex gap-4 items-center pl-5`}>
          <Link to="/" className="flex items-center">
            <img src={Logo} alt="logo" className={`w-full`} />
          </Link>
        </div>
        <div className={`flex pr-5`}>
          {toggle ? (
            <AiOutlineClose
              className="text-3xl cursor-pointer z-20"
              style={{ color: "white" }}
              onClick={() => setToggle(!toggle)}
            />
          ) : (
            <AiOutlineMenu
              className="text-3xl cursor-pointer"
              onClick={() => setToggle(!toggle)}
            />
          )}

          <div
            className={`${
              !toggle ? "hidden" : ""
            } bg-black text-white p-6 absolute right-0 top-0 w-[80vw] h-[100vh] flex flex-col justify-center transition-transform duration-300 ease-in-out transform`}
          >
            {NavLinks.map((nav, index) => (
              <li
                key={index}
                className={`list-none text-2xl py-2`}
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <NavLink
                  to={nav.url}
                  className={({ isActive }) =>
                    isActive ? "border-b-2 border-primary pb-1" : ""
                  }
                >
                  {nav.title}
                </NavLink>
              </li>
            ))}
            <div className="flex mt-20">
              <Link
                to="/ContactUs"
                className=" bg-primary text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                Contact Us
              </Link>
            </div>
            <div className="py-10">
              <h1 className="text-xl text-gray-500">
                Follow us on social media
              </h1>
              <div className="pt-2 flex">
                <ul className="flex gap-2">
                  <li className="bg-primary p-3 rounded-full">
                    <button
                      aria-label="Twitter"
                      className="flex items-center"
                      onClick={() =>
                        window.open("https://x.com/GridHall", "_blank")
                      }
                    >
                      <BsTwitterX size={20} color="white" />
                    </button>
                  </li>
                  <li className="bg-primary p-3 rounded-full">
                    <button
                      aria-label="LinkedIn"
                      className="flex items-center"
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/company/gridhall-limited/",
                          "_blank"
                        )
                      }
                    >
                      <BsLinkedin size={20} color="white" />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.nav>
  );
};

export default Header;
