import React from "react";
import { GridImage, LogoIcon } from "../assets";
import { IoCheckmarkCircle } from "react-icons/io5";

const ThankYou = () => {
  return (
    <section className="w-[90%] lg:w-[65%] mx-auto relative flex flex-col items-center justify-center h-screen">
      <div className="absolute inset-0 z-0">
        <img
          src={GridImage}
          alt="Grid Background"
          className="w-full h-full object-contain object-center"
        />
      </div>
      <div className="w-full lg:w-1/2 mx-auto absolute flex flex-col">
        <div className="flex justify-center items-center gap-2 mx-auto border border-black/10 bg-[#ECECEC] text-sm px-4 py-3 rounded-full mb-6">
          <img src={LogoIcon} alt="logo" className="w-8" />
          <p>Invest With Gridhall</p>
        </div>
        <div className="flex flex-col gap-4 bg-white p-8 lg:p-16 rounded-3xl border drop-shadow-md">
          <div className="flex justify-center">
            <IoCheckmarkCircle
              size={120}
              color="#008CFE"
              className="bg-blue-200 rounded-full p-3"
            />
          </div>
          <div className="flex flex-col gap-4 text-center justify-center mb-4">
            <h1 className="text-lg">Successfully Summited!</h1>
            <p>
              The team will reach out to you shortly with the investment details
              via email
            </p>
          </div>
          <div className="relative text-center space-y-10">
            <a
              href="/Products"
              className="inline-flex gap-2 z-50 items-center bg-primary text-white px-10 py-4 rounded hover:bg-blue-700 transition relative" // Set relative positioning to ensure it's on top
            >
              Back to home
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThankYou;
