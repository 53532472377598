export const NavLinks = [
  {
    id: 1,
    title: "Products",
    url: "/Products",
  },
  {
    id: 2,
    title: "About Us",
    url: "/About",
  },
  {
    id: 3,
    title: "Investment",
    url: "/Investment",
  },
];
