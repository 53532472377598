import { LogoBig } from "../assets";
import { motion } from "framer-motion";
import { fadeIn, staggerContainer, textVariant } from "../utils/motion";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const timer = setTimeout(() => {
        window.location.pathname = "/Products";
        // window.history.pushState("/Products");
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <div className="w-[80%] h-screen relative flex flex-col mx-auto justify-center items-center">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className="flex flex-col items-center justify-center text-center space-y-10"
      >
        <motion.div className="flex items-center space-x-4">
          <a href="/Products">
            <motion.img
              variants={fadeIn("up", "tween", 0.2, 1)}
              src={LogoBig}
              alt=""
              className="w-[150px]"
            />
          </a>
        </motion.div>
        <div>
          <motion.h1 variants={textVariant(0.5)} className="text-6xl uppercase">
            Gridhall
          </motion.h1>
          <motion.p
            variants={fadeIn("up", "tween", 0.2, 1)}
            className="text-3xl italic text-gray-500"
          >
            incorporated
          </motion.p>
        </div>
      </motion.div>
    </div>
  );
};

export default Home;
